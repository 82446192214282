<template>
  <div>
    <img class="img-fluid mt-5" src="@/assets/img/GPS.jpg"/>

    <footer id="footer" class="d-flex align-items-center">
      <div class="container-fluid">
        <div class="row justify-content-md-center my-5">
          <div class="col-md-4 text-center">
            <h2 class="footer-heading">Contact us</h2>

          </div>
        </div>
        <div class="row justify-content-md-center my-5">
          <div class="col-md-3 text-center my-3">
            <a class="contact-link" href="tel:+966506651155" target="_blank">
              <font-awesome-icon :icon="faPhoneSquareAlt"></font-awesome-icon>
              <span>+966 50 665 1155</span>
            </a>
          </div>
          <div class="col-md-3 text-center my-3">
            <a
              class="contact-link"
              href="https://wa.me/966506651155"
              target="_blank"
            >
              <font-awesome-icon :icon="faWhatsapp"></font-awesome-icon>
              <span>+966 50 665 1155</span>
            </a>
          </div>
        </div>
        <h5 class="text-center">All Rights Reserved .Green closet 2023 ©</h5>
      </div>
    </footer>
    <footer>
      <div class="container">
        <div class="footer">
          <ul class="footer_links">
            <!-- <li><a href="">Status</a></li> -->
            <li>
              <a
                href="terms.html"
                target="_blank"
                >Privacy & Terms</a
              >
            </li>
            <li>
              <a href="https://wa.me/971563806000" target="_blank">Contact Us</a>
            </li>
          </ul>
          <ul class="footer_links social_">
            <li></li>
            <li>
              <a href="https://www.instagram.com/greenclosetksa/">
                <font-awesome-icon :icon="faInstagram"></font-awesome-icon>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/greenclosetksa">
                <img src="@/assets/img/x.svg" width="16" height="16">
              </a>
            </li>
            <li>
              <a href="https://facebook.com/greenclosetksa">
                <font-awesome-icon :icon="faFacebook"></font-awesome-icon>
              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@greenclosetksa">
                <font-awesome-icon :icon="faTiktok"></font-awesome-icon>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faTwitter,
  faWhatsapp,
  faTiktok,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import { faPhoneSquareAlt } from "@fortawesome/free-solid-svg-icons";

export default {
  components: { FontAwesomeIcon },
  data() {
    return {
      faBars,
      faTimes,
       faInstagram,
      faTwitter,
      faTiktok,
  faFacebook,
      faPhoneSquareAlt,
      faWhatsapp,
      article: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn-primary {
  height: 45px;
  min-width: 208px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
}
</style>